<template>
  <div class="public-banner">
    <div
      class="banner-bg"
      :style="`background: url(${bannerInfoConfig.img}) center center / cover no-repeat; height: ${bannerInfoConfig.height}px`"
    >
      <div class="banner-info container">
        <!-- 带搜索 -->
        <el-input
          v-if="isSearch"
          v-model="keywords"
          class="banner-search"
          @keyup.enter.native="handleSearch(keywords)"
        >
          <i
            slot="suffix"
            class="iconfont icon-a-search1x"
            @click="handleSearch(keywords)"
          ></i>
        </el-input>
        <!-- 带标题 -->
        <h2 v-if="bannerInfo.title" class="banner-title">{{ bannerInfo.title }}</h2>
      </div>
    </div>
    <!-- 带悬浮内容块 上下布局 -->
    <div v-if="Object.keys(bannerContent).length" class="banner-content content-bg container">
      <!-- 标题 -->
      <h1 v-if="bannerContent.title" class="content-title">{{ bannerContent.title }}</h1>
      <!-- 文本内容 -->
      <p v-if="bannerContent.desc" class="content-desc" v-html="bannerContent.desc"></p>
      <!-- 跳转链接 -->
      <a
        v-if="bannerContent.linkText"
        :href="bannerContent.link"
        target="_blank"
        rel="nofollow noopener noreferrer"
        class="content-link"
      >{{ bannerContent.linkText }}</a>
    </div>
    <!-- 带悬浮内容块 左右布局 -->
    <div v-if="Object.keys(bannerContentTwo).length" class="content-bg banner-content-two container">
      <div class="text-box">
        <!-- 标题 -->
        <h3 v-if="bannerContentTwo.title" class="content-title-two">{{ bannerContentTwo.title }}</h3>
        <!-- 日期 -->
        <p v-if="bannerContentTwo.data" class="content-data-two">{{ bannerContentTwo.data }}</p>
      </div>
      <!-- 右侧图片 -->
      <div
        v-if="bannerContentTwo.img"
        class="img-box"
        :style="`background: url(${bannerContentTwo.img}) center center / contain no-repeat;`"
      ></div>
    </div>
    <!-- 自定义模板 -->
    <div v-if="isCustom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // banner
    bannerInfo: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // 是否展示搜索
    isSearch: {
      type: Boolean,
      default: false
    },
    // 是否展示悬浮内容块（上下布局）
    bannerContent: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // 是否展示悬浮内容块（左右布局）
    bannerContentTwo: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // 自定义模板
    isCustom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keywords: '', // 搜索关键字
      bannerInfoDefault: { // banner高度
        height: 400
      }
    }
  },
  computed: {
    bannerInfoConfig() {
      return {
        ...this.bannerInfoDefault,
        ...this.bannerInfo
      }
    }
  },
  methods: {
    // 搜索
    handleSearch(keywords) {
      this.$emit('search', keywords)
    }
  }
}
</script>

<style lang="less" scoped>
.public-banner {
  .banner-bg {
    width: 100%;
    position: relative;
    .banner-info {
      width: 92%;
      max-width: 1060px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .banner-search {
        text-align: center;
        position: relative;
        /deep/ .el-input__inner {
          height: 72px;
          padding-right: 100px;
          border-radius: 4px;
          font-size: 16px;
        }
        /deep/ .el-input__suffix {
          cursor: pointer;
          right: 42px;
          padding-top: 16px;
          .el-input__suffix-inner {
            .iconfont {
              font-size: 40px;
              color: #333;
            }
          }
        }
      }
      .banner-title {
        font-size: 42px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 56px;
        text-align: center;
      }
    }
  }
  .content-bg {
    background: #FFFFFF;
    box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
    border-radius: 4px;
    position: relative;
  }
  .banner-content {
    min-height: 240px;
    text-align: center;
    margin-top: -160px;
    padding: 30px 40px;
    .content-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 48px;
    }
    .content-desc {
      font-size: 16px;
      color: #606266;
      line-height: 1.5;
      margin-top: 26px;
    }
    .content-link {
      display: inline-block;
      color: @theme;
      font-size: 16px;
      line-height: 21px;
      margin-top: 16px;
      font-style: italic;
      text-decoration: underline;
    }
  }
  .banner-content-two {
    min-height: 366px;
    margin-top: -183px;
    display: flex;
    .text-box {
      width: 50%;
      padding: 42px 38px;
      .content-title-two {
        font-size: 32px;
        font-weight: 600;
        color: #303133;
        line-height: 43px;
      }
      .content-data-two {
        font-size: 16px;
        color: #606266;
        line-height: 21px;
        margin-top: 106px;
      }
    }
    .img-box {
      width: 50%;
    }
  }
  @media screen and (max-width: 1366px) {
    .banner-bg {
      .banner-info {
        max-width: 900px;
        .banner-search {
          /deep/ .el-input__inner {
            height: 56px;
            padding-right: 70px;
            font-size: 14px;
          }
          /deep/ .el-input__suffix {
            right: 24px;
            padding-top: 16px;
            .el-input__suffix-inner {
              .iconfont {
                font-size: 24px;
              }
            }
          }
        }
        .banner-title {
          font-size: 36px;
          line-height: 50px;
        }
      }
    }
    .banner-content {
      .content-title {
        font-size: 28px;
        line-height: 40px;
      }
      .content-desc {
        font-size: 14px;
        margin-top: 20px;
      }
      .content-link {
        font-size: 14px;
      }
    }
    .banner-content-two {
      .text-box {
        .content-title-two {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .banner-bg {
      .banner-info {
        .banner-title {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
    .banner-content {
      .content-title {
        font-size: 24px;
        line-height: 34px;
      }
    }
    .banner-content-two {
      .text-box {
        .content-title-two {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
   .banner-bg{
        height: 232px !important;
        .banner-info{
          .banner-search{
            /deep/ .el-input__inner {
              height: 48px;
            }
          }
        }
      }

  }
}
</style>
