<template>
  <div class="webinars-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="webinars-content container">
      <div v-for="(item, index) in list" :key="index" class="upcoming-box">
        <public-title :title="item.menu_name" />
        <ul class="upcoming-list">
          <li v-for="(itemChild, indexChild) in item.news_list" v-show="indexChild < showNum" :key="indexChild" class="upcoming-item">
            <router-link :to="`/events/webinars/detail?id=${itemChild.id}`" class="link">
              <div class="img-box">
                <img :src="itemChild.image" class="img-bg" />
              </div>
              <div class="text-box">
                <h5 class="title">{{ itemChild.name }}</h5>
                <p class="data">{{ itemChild.post_date }}</p>
              </div>
            </router-link>
          </li>
        </ul>
        <div v-if="item.news_list.length >= 6" class="past-arrow" @click="handleShow($event)">
          <span class="iconfont icon-a-xiala1x" :class="{more : isMore}"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapActions } from 'vuex'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { newsMenuApi } from '@/api/events'
export default {
  components: {
    PublicBanner,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {
        title: '',
        img: ''
      },
      isMore: false, // 是否超出
      list: [], // 类别列表
      showNum: 6 // 默认列表中显示的数量
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getNewsMenu()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 42 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 获取新闻类别下的数据
    getNewsMenu() {
      newsMenuApi().then(res => {
        this.list = res.data
      })
    },
    // 展开折叠
    handleShow(e) {
      const size = e.currentTarget.previousElementSibling.children.length
      if (this.showNum > 6) {
        this.showNum = 6
        this.isMore = false
      } else {
        this.showNum = size
        this.isMore = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.webinars-wrap {
  .webinars-content {
    padding: 72px 0;
    .upcoming-box {
      &:first-child {
        margin-bottom: 64px;
      }
      .upcoming-list {
        display: flex;
        flex-wrap: wrap;
        .upcoming-item {
          width: calc(33.333333% - 11px);
          margin: 0 16px 16px 0;
          box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover{
            .title,.data{
              color: @theme !important;
            }
          }
          .link {
            display: block;
            .img-box {
              overflow: hidden;
              text-align: center;
              &:hover{
                .img-bg{
                  .scale();
                }
              }
              .img-bg {
                height: 238px;
                .transition();
              }
            }
            .text-box {
              padding: 24px 16px;
              .title {
                // height: 50px;
                overflow: hidden;
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
              }
              .data {
                font-size: 16px;
                color: #606266;
                line-height: 21px;
                margin-top: 10px;
              }
            }
          }
        }
      }
      .past-arrow {
        padding: 12px 0;
        text-align: center;
        cursor: pointer;
        .transition();
        &:hover {
          background: #F0F2F5;
        }
        .iconfont {
					display: inline-block;
          font-size: 22px;
					transform: rotate(0);
					.transition();
					&.more{
						transform: rotate(180deg);
					}
        }
      }
    }
  }
	@media screen and (max-width:768px) {
		/deep/.public-banner{
			.banner-bg{
				.banner-info{
					.banner-title{
						font-size: 24px;
						line-height: 32px;
					}
				}
			}
		}
		.webinars-content{
			padding: 24px 0 47px;
			.upcoming-box{
				margin-bottom: 8px;
				&:first-child{
					margin-bottom: 8px;
				}
				/deep/.public-title{
					.title{
						font-size: 24px;
						line-height: 32px;
						margin-bottom: 16px;
					}
				}
				.upcoming-list{
					flex-direction: column;
					.upcoming-item{
						width: 100%;
						margin-right: 0;
						.link{
							.img-box{
								.img-bg{
									height: 192px;
								}
							}
							.text-box{
								padding: 16px;
								.title{
									font-size: 16px;
									line-height: 21px;
								}
								.data{
									font-size: 14px;
									line-height: 19px;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
