<template>
  <div class="webinars-detail">
    <public-banner :banner-info="bannerInfo" :banner-content-two="bannerContentTwo" />
    <div class="detail-content container">
      <public-title :title="LP.lang_overview" />
      <div class="text-box">
        <p class="text" v-html="data.content"></p>
      </div>
      <el-button
        v-if="data.register_button"
        class="detail-btn"
        type="info"
        target="_blank"
        rel="nofollow noopener noreferrer"
        @click="register"
      >{{ data.register_button }}</el-button>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapState } from 'vuex'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { newsDetailApi } from '@/api/events'
export default {
  components: {
    PublicBanner,
    PublicTitle
  },
  data() {
    return {
      data: {}, // 详情数据
      bannerContentTwo: {}
    }
  },
  computed: {
    ...mapState({
      bannerInfo: (state) => state.common.bannerInfo
    })
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取轮播图
    async getBanner() {
      this.$store.dispatch('common/getBannerImg', { num: 42 })
    },
    // 获取新闻详情数据
    getDetailData() {
      newsDetailApi({
        id: this.$route.query.id
      }).then(res => {
        this.data = res.data
        this.bannerContentTwo = {
          title: res.data.name,
          data: res.data.post_date,
          img: res.data.image
        }
      })
    },
    // 跳转登记页面
    register() {
      window.open(this.data.register_button_url)
    }
  }
}
</script>

<style lang="less" scoped>
.webinars-detail {
  /deep/ .public-banner {
     .banner-content-two {
       .text-box{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         .content-data-two {
           margin-top: 0;
         }

       }
     }
  }
  /deep/ .detail-content {
    padding: 72px 0;
    .text-box {
      .text {
        font-size: 16px;
        color: #606266;
        line-height: 21px;
        width: 100%;
        p {
          img {
            width: 100%;
          }
        }
      }
    }
    .detail-btn {
      display: block;
      margin: 72px auto 0 auto;
			background-color: @theme;
    }
  }
	@media screen and (max-width: 768px) {
		/deep/.public-banner{
			.banner-content-two{
				min-height: 319px;
				margin-top: -192px;
				flex-direction: column;
				align-items: center;
				.text-box{
					width: 100%;
					padding: 24px 16px;
					.content-data-two{
						margin-top: 24px;
					}
				}
				.img-box{
					height: 192px;
					width: 100%;
				}
			}
		}
		.detail-content{
			padding: 24px 0 48px;
			/deep/.public-title{
				.title{
					font-size: 24px;
					line-height: 32px;
					margin-bottom: 16px;
				}
			}
			.text-box{
				.text{
					font-size: 14px;
					line-height: 19px;
				}
			}
			.detail-btn{
				margin: 16px auto 0;
				font-size: 16px;
				line-height: 21px;
				padding: 14px 20px;
				background-color: @theme;
				border:1px solid @theme;
			}
		}
	}
}
</style>
