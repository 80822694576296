<template>
  <div v-if="Object.keys(eventData).length" class="events-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="events-content container">
      <div v-for="(item, index) in eventData.nav" :key="index" class="partner-box">
        <div class="text-box">
          <h3 class="partner-title">{{ item.nav_name }}</h3>
          <p class="partner-data" v-html="item.nav_description"></p>
          <el-button type="info" @click="watchReplay(item)">{{ item.lang_watch_replay }}</el-button>
        </div>
        <div class="img-box">
          <a
            class="play"
            :href="item.nav_video"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img :src="item.nav_image" alt="" />
          </a>
        </div>
      </div>
      <div class="webinars-box">
        <public-title :title="eventData.webinars" />
        <ul class="webinars-list">
          <li v-for="(item, index) in eventData.news" :key="index" class="webinars-item">
            <router-link :to="`/events/webinars/detail?id=${item.id}`" class="link">
              <div class="img-box">
                <div :style="`background: url(${item.image}) center center / contain no-repeat`" class="img-bg"></div>
              </div>
              <div class="text-box">
                <h5 class="title">{{ item.name }}</h5>
                <p class="data">{{ item.post_date }}</p>
              </div>
            </router-link>
          </li>
        </ul>
        <el-button class="webinars-btn" type="info" @click="handleListPage">{{ eventData.view_all }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { newsHomeApi } from '@/api/events'
import { mapActions } from 'vuex'
export default {
  components: {
    PublicBanner,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {
        title: '',
        img: ''
      },
      eventData: {} // 新闻首页数据
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    this.getNewsData()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 92 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 获取新闻首页数据
    getNewsData() {
      newsHomeApi().then(res => {
        this.eventData = res.data
      })
    },
    // 观看按钮数据
    watchReplay(item) {
      window.open(item.lang_watch_replay_link)
    },
    // 查看更多类别列表
    handleListPage() {
      this.$router.push({ path: `/events/webinars` })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
@import '~@/styles/common/color.less';
.events-wrap {
  .events-content {
    padding: 72px 0;
    .partner-box {
      display: flex;
      border-radius: 4px;
      position: relative;
      // background: #FFFFFF;
      margin-bottom: 15px;
      box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
      .text-box {
          width: 50%;
          padding: 26px 38px;
          .partner-title {
            font-size: 36px;
            font-weight: 400;
            line-height: 48px;
          }
          .partner-data {
            font-size: 16px;
            color: #606266;
            line-height: 21px;
            margin: 16px 0 24px 0;
          }
          .el-button{
            background-color: @theme;
            border: 1px solid @theme;
            &:hover{
              opacity: 0.7;
            }
          }
      }
      .img-box {
          position: relative;
          width: 50%;
          .play {
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
          /deep/.iconfont{
            color: rgba(255, 255, 255, 0.7);
            font-size: 36px;
          }
      }
      &:last-child {
        margin-bottom: 48px;
      }
    }
    .webinars-box {
      .webinars-list {
        display: flex;
        flex-wrap: wrap;
        .webinars-item {
          width: calc(33.333333% - 11px);
          margin: 0 16px 16px 0;
          box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover{
            .title, .data{
              color: @theme !important;
            }
          }
          .link {
            display: block;
            .img-box {
              overflow: hidden;
              &:hover{
                .img-bg{
                  .scale();
                }
              }
              .img-bg {
                height: 238px;
                .transition();
              }
            }
            .text-box {
              padding: 24px 16px;
              .title {
                // height: 50px;
                overflow: hidden;
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
              }
              .data {
                font-size: 16px;
                color: #606266;
                line-height: 21px;
                margin-top: 10px;
              }
            }
          }
        }
      }
      .webinars-btn {
        text-align: center;
        display: block;
        margin: 24px auto 0 auto;
				background-color: @theme;
				border:1px solid @theme;
				&:hover{
					opacity: 0.7;
				}
      }
    }
  }
	@media screen and (max-width:768px) {
		/deep/.public-banner{
			.banner-bg{
				.banner-info{
					.banner-title{
						font-size: 24px;
						line-height: 32px;
					}
				}
			}
		}
		.events-content{
			padding: 25px 0 48px;
			.partner-box{
        margin-bottom: 24px;
        flex-direction: column;
        align-items: center;
        .img-box{
            min-height: 168px;
            width: 100%;
        }
        .text-box{
            width: 100%;
            padding: 16px 19px 24px;
            .partner-title{
              font-size: 24px;
              line-height: 32px;
            }
            .partner-data{
              font-size: 14px;
              line-height: 19px;
              margin: 16px 0;
            }
            .el-button{
              font-size: 16px;
              line-height: 21px;
              padding: 14px 20px;
              background-color: @theme;
              border:1px solid @theme;
            }
        }
        &:last-child {
          margin-bottom: 0;
        }
			}
			.webinars-box{
				/deep/.public-title{
					.title{
						font-size: 24px;
						line-height: 32px;
						margin-bottom: 16px;
					}
				}
				.webinars-list{
					flex-direction: column;
					.webinars-item{
						width: 100%;
						margin: 0 0 15px 0;
						.link{
							.text-box{
								padding: 16px;
								.title{
									font-size: 16px;
									line-height: 21px;
								}
								.data{
									font-size: 14px;
									line-height: 19px;
								}
							}
							.img-box{
								.img-bg{
									height: 192px;
								}
							}
						}
					}
				}
				.webinars-btn{
					font-size: 16px;
					line-height: 21px;
					padding: 14px 20px;

				}
			}
		}
	}
}
</style>
