import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  newsHome: '/news/news_home', // 新闻首页
  newsMenu: '/news/news_menu', // 新闻类别下的数据
  newsDetail: 'news/news_detail' // 新闻详情
}

// 新闻首页
export const newsHomeApi = () => _axios.get(apiUrl.newsHome)
// 新闻类别下的数据
export const newsMenuApi = () => _axios.get(apiUrl.newsMenu)
// 新闻详情
export const newsDetailApi = (params) => _axios.post(apiUrl.newsDetail, params)
